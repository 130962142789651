import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { EnvHelper } from "./Environment"
import arbitrum_logo from "../src/assets/Arbitrum.svg";

export const WEBHOOK_URL: string = EnvHelper.env.REACT_APP_WEBHOOK_URL;
export const IPFS_URL = EnvHelper.env.REACT_APP_IPFS_URL;
export const NEED_GAS_ESTIMATION = EnvHelper.env.NEED_GAS_ESTIMATION;


export const APP_STORAGE_USER_KEY = '@user_key';
export enum NetworkId {
  HARDHAT = 31337,
  MUMBAI = 80001,
  GOERLI = 5,
  ARBITRUMTEST = 421613,
  ARBITRUM = 42161
}

export const MASTER_KEY: string = EnvHelper.env.REACT_APP_MASTER_KEY;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  [NetworkId.ARBITRUM]: {
    DAOAuthority: "0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07",
    EntityRegistry: "0xB52de321B7cc50a679bfA972318660C9B5DC1732",
    CollectionFactory: "0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0",
    PrivateForwarder: "0xA0C19aaa2ac7a6e5bF2012F0D66Ea9a266A0788F",
    User: "0x8f543c4077437513eac2Ffe97cC88BE2ef25550d",
    OrderDispatcher: "0xCC9601EE5dF9D612fE7E508f222c2b345b1a304d",
    DAOERC20: "0x0c810C82e8c9c31B620f13224a2866e54d9629d2",
    CollectionManager: "0x1F75698fA087F77Bd02D2636317EA12F4A74a081",
    ExternalCollectionManager: "0x9839E731e7606eC68F97b8AA4d61a3013812AfdB",
    TokenPriceCalculator: "0x76195282b767286B8929140a6E272C72332FDcCa",
    RelayerAddress: "0x225bb59ae59101008b0bd007df5ddfb73fead918",
    DispatcherHelper: "0xC73e6Ac8612B7e44a3fC8C4b8455F9Ed44955A9c"
  },

  [NetworkId.ARBITRUMTEST]: {
    DAOAuthority: "0xB7433289940A0D942B37FC060fd1815C29A79d17",
    EntityRegistry: "0xc91B0d95079C931B6174a5ff394179BDD9325586",
    CollectionFactory: "0x7Fb4CD96E74F842d18bE2b48af9a7682fef61367",
    PrivateForwarder: "0xcBE440f712b63975a54006F0C4d588a034fa2519",
    User: "0xf3DBbf821d94B37076F1CAe9CB43f79D823FD68a",
    OrderDispatcher: "0x7cCb86A354A1f553F27B34c4b6c6E34f99259808",
    DAOERC20: "0x7729e37190a30eC529bbebD8c181Ea2797ea76c3",
    CollectionManager: "0x2D3a80C3A24cf83eAc5646917AB5F129796f7aC5",
    ExternalCollectionManager: "0xCB21Eb65723050841B8FFa849c098687de175bFA",
    TokenPriceCalculator: "0x4534c0c01Ecb75c409f492965406D2D87249D28C",
    RelayerAddress: "0x0861fbabc1e5ccfc7ef419a68cbbdd90f244c2c8",
    DispatcherHelper: "0x3077e62083882427476B06c91b276f1814Bf431c",
  },

};


/**
 * Network details required to add a network to a user's wallet, as defined in EIP-3085 (https://eips.ethereum.org/EIPS/eip-3085)
 */

interface INativeCurrency {
  name: string;
  symbol: string;
  decimals?: number;
}

interface INetwork {
  chainName: string;
  chainId: number;
  nativeCurrency: INativeCurrency;
  rpcUrls: string[];
  blockExplorerUrl: string;
  image: any,//SVGImageElement;
  imageAltText: string;
  uri: () => string;
  isOracleIntegrated: boolean;
}

const getURI = (networkid: number) => {
  if (networkid == NetworkId.ARBITRUM)
    return `https://arbitrum-mainnet.infura.io/v3/${EnvHelper.env.REACT_APP_INFURA_IDS}`;
  else if (networkid == NetworkId.ARBITRUMTEST)
    return `https://arbitrum-goerli.infura.io/v3/${EnvHelper.env.REACT_APP_INFURA_IDS}`;
  else if (networkid == NetworkId.GOERLI)
    return `https://goerli.infura.io/v3/${EnvHelper.env.REACT_APP_INFURA_IDS}`;
  return `https://arbitrum-mainnet.infura.io/v3/${EnvHelper.env.REACT_APP_INFURA_IDS}`;
}

export const getNetwork = () => {
  return Number(EnvHelper.env.REACT_APP_DEFAULTNETWORK);
}

export const getNetworkName = () => {
  return NETWORKS[getNetwork()].chainName;
}

export const getNetworkRPC = () => {
  return NETWORKS[getNetwork()].rpcUrls[0];
}
export const getNetworkURI = () => {
  return NETWORKS[getNetwork()].uri();
}

export const getStaticProvider = () => {
  return new StaticJsonRpcProvider(NETWORKS[getNetwork()].uri());
}

export const getAnynetStaticProvider = (chainId: NetworkId) => {
  return new StaticJsonRpcProvider(NETWORKS[chainId].uri());
};

export const gasEstimationEnabled = () => {
  return NEED_GAS_ESTIMATION == "true"
}

export const APP_ALL_TRANSACTION = '@alltransaction';
export const APP_STORAGE_GET_USERDETAILS = (useraddress) => { return '@userDetails_' + useraddress.toLowerCase() };
export const APP_STORAGE_GET_COLLECTIONDETAILS = (passportaddress) => { return '@collection_' + passportaddress.toLowerCase() };
export const APP_GET_CANCEL_ORDER_ID = '@cancelOrderId';



export const NETWORKS: { [key: number]: INetwork } = {
  [NetworkId.ARBITRUM]: {
    chainName: "Arbitrum",
    chainId: NetworkId.ARBITRUM,
    nativeCurrency: {
      name: "Arbitrum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc", "https://arbitrum-mainnet.infura.io/v3"],
    blockExplorerUrl: "https://arbiscan.io",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Mainnet",
    uri: () => getURI(NetworkId.ARBITRUM),
    isOracleIntegrated: true

  },
  [NetworkId.ARBITRUMTEST]: {
    chainName: "Arbitrum Testnet",
    chainId: NetworkId.ARBITRUMTEST,
    nativeCurrency: {
      name: "Arbitrum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli-rollup.arbitrum.io/rpc", "https://arbitrum-goerli.infura.io/v3"],
    blockExplorerUrl: "https://goerli.arbiscan.io/",
    image: arbitrum_logo,
    imageAltText: "Arbitrum Testnet",
    uri: () => getURI(NetworkId.ARBITRUMTEST),
    isOracleIntegrated: true
  },
  [NetworkId.GOERLI]: {
    chainName: "Goerli",
    chainId: NetworkId.GOERLI,
    nativeCurrency: {
      name: "Goerli",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli.infura.io/v3/"],
    blockExplorerUrl: "https://goerli.etherscan.io",
    image: arbitrum_logo,
    imageAltText: "Goerli Testnet",
    uri: () => getURI(NetworkId.GOERLI),
    isOracleIntegrated: true
  },

};