
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
//import { RootState } from "/src/store/store";
import { PrivateForwarder } from "../typechain/PrivateForwarder";

import { RootState } from "../store/store";
import { ethers, Wallet } from "ethers";
import { addresses, WEBHOOK_URL, APP_STORAGE_GET_USERDETAILS, MASTER_KEY, APP_ALL_TRANSACTION, APP_GET_CANCEL_ORDER_ID, gasEstimationEnabled, APP_STORAGE_GET_COLLECTIONDETAILS } from "../constants"
import { Dispatcher__factory, DispatcherHelper__factory, Entity__factory, PrivateForwarder__factory, TokenPriceCalculator__factory, User__factory } from "../typechain/factories";
import { CollectionManager__factory } from "../typechain/factories/CollectionManager__factory";
import { GetBanUserMessage, GetCreateCancelOrderMessage, GetCreateOrderMessage } from "../helpers/MsgHelper";

import { setAll } from "./helpers";
import { IServeOrderAsyncThunk, IMessageMetaData, IEntityData, UserOrderDetail, IBANOrderAsyncThunk, ICollectionAsyncThunk, ICollectionDetail } from "./interfaces"
import { CheckAndGetIPFSLink, getIPFSData, getIPFSLink } from "../helpers/ipfs";
import { getData, storeData } from "../store/AppStorage";
import AwaitLock from "../helpers/AwaitLock";
import { OfferType, UICustomOfferType } from "../enums/offers.enum";
import { CollectionType } from "../enums/collection.enum";

let lock = new AwaitLock();

export interface IServeOrderSliceData {
  readonly AllOfferDetails: any[];
  readonly loading: boolean;
  readonly processing: boolean;
  readonly searchText: string;
}

const initialState: IServeOrderSliceData = {
  AllOfferDetails: [],
  loading: false,
  processing: false,
  searchText: ''
};

let activeOrdersList: any[] = [];

export const SendMetaTX = createAsyncThunk("app/SignMetaTX", async ({ networkID, provider, to, wallet, data }: IMessageMetaData, { dispatch, getState }): Promise<any> => {
  await lock.acquireAsync();
  try {
    console.log("transaction start")
    return await (SendMetaTXCall({ networkID, provider, to, wallet, data }));
  } finally {
    console.log("transaction end");
    await new Promise(resolve => setTimeout(resolve, 1000));
    lock.release();
  }
});

export const SendMetaTXCall = (async ({ networkID, provider, to, wallet, data }: IMessageMetaData): Promise<any> => {
  const from = await wallet.getAddress();
  const ADDRESS_FORWADER = addresses[networkID].PrivateForwarder;
  const private_forwarder = PrivateForwarder__factory.connect(ADDRESS_FORWADER, provider);
  const request = await buildRequest(private_forwarder, { from, to, data }, networkID, wallet);
  const toSign = await buildTypedData(private_forwarder, request);
  const signature = await wallet._signTypedData(toSign.domain, toSign.types, toSign.message);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(signature);

  let responseData = await fetch(WEBHOOK_URL, {
    method: "POST",
    body: JSON.stringify({ signature, request, masterSignature }),
    headers: { "Content-Type": "application/json" },
  });
  let response = await responseData.json();
  if (response.status == 'success') {
    let tx = (JSON.parse(response.result)).txHash;
    const txReceipt = await provider.waitForTransaction(tx);
  }
  // await new Promise(resolve => setTimeout(resolve, 3000));//delay in relay response and execution in the actuall task
  return response;
});

const buildRequest = async (forwarder: PrivateForwarder, input: any, networkID, wallet: Wallet): Promise<any> => {
  const nonce = (await forwarder.getNonce(input.from)).toString();
  // if (networkID === NetworkId.POLYGON_MUMBAI_TESTNET || networkID === NetworkId.POLYGON_MAINNET) {
  //   return { value: 0, gas: 1e6, nonce, ...input };
  // }
  // else {
    let gas = 2e6;
    const submissionTime = new Date().getTime();
    try {
      //const gasPrice = await forwarder.provider.getGasPrice();
      const request = { value: 0, gas: 15e6, nonce, ...input };

      let toSign = await buildTypedData(forwarder, request);
      let signature = await wallet._signTypedData(toSign.domain, toSign.types, toSign.message);
      const data = forwarder.interface.encodeFunctionData("execute", [request, signature]);

      const gasEstimated: number = (await forwarder.provider.estimateGas({
        from: addresses[networkID].RelayerAddress,
        to: addresses[networkID].PrivateForwarder,
        data: data
      })).toNumber();
      console.log("timeend:", new Date().getTime());
      if (gasEstimationEnabled()) {
        // calculate forwarder's stack reserve
        //const stackReserve = req.gas / 64;
        const stackReserve = Math.round(gasEstimated / 64);
        // real usage by internal call
        const intUsage = gasEstimated - stackReserve;
        // add markup to cover gas fluctuations
        gas = Math.round(intUsage * (1.10));
        console.log(`new gasEstimation::gasEstimated: ${gasEstimated} stackReserve: ${stackReserve} intUsage: ${intUsage}; with markup: ${gas}`);        //new calculation-end
      }
      else {
        gas = Math.round(gasEstimated * 2); //current working calculation
        console.log(`gasEstimated: ${gasEstimated}; with markup: ${gas}`);
      }



    }
    catch (e) {
      console.log("timeend-error:", new Date().getTime(), e);
    }
    return { value: 0, gas: gas, nonce, ...input };
  //}
}

const buildTypedData = async (forwarder: PrivateForwarder, request: any) => {
  const chainId = (await forwarder.provider.getNetwork()).chainId;
  const typeData = getMetaTxTypeData(chainId, forwarder.address);
  return { ...typeData, message: request };
}

const getMetaTxTypeData = (chainId: number, verifyingContract: string): any => {
  return {
    types: {
      ForwardRequest: [
        { name: "from", type: "address" },
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "gas", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "data", type: "bytes" },
      ],
    },
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest'
  }
};

const getCurrentTimeUTC = () => {
  var tmLoc = new Date();
  return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
}
export const serveOrder = createAsyncThunk("app/serveOrder", async ({ networkID, provider, address, orderInfo, wallet }: IServeOrderAsyncThunk, { dispatch, getState }): Promise<any> => {
  //both Event & offer having same method name so need not create new message type
  const inBytes = ethers.utils.formatBytes32String(JSON.stringify({ 'servedOn': getCurrentTimeUTC() }));
  const data = GetCreateOrderMessage(orderInfo.reservationId, inBytes);
  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].OrderDispatcher,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    await dispatch(SendMetaTX(msg));

    //await new Promise(resolve => setTimeout(resolve, 2000));//later we can remove
    const state = getState() as RootState;
    const orderDispatch = Dispatcher__factory.connect(addresses[networkID].OrderDispatcher, provider);
    let orderDetails = await orderDispatch.reservations(orderInfo.reservationId);
    let isExpiry = Number(orderDetails.expiry) * 1000 < (new Date().getTime());
    if (orderDetails.cancelled || orderDetails.fulfilled || isExpiry) {
      let allUserOrder = state.serverOrder.AllOfferDetails.filter(x => x.reservationId != orderInfo.reservationId);
      activeOrdersList = activeOrdersList.filter(x => x.reservationId != orderInfo.reservationId);
      console.log("Order successfully served for reservation" + orderInfo.reservationId);
      await storeData(APP_ALL_TRANSACTION, allUserOrder);
      return { AllOfferDetails: allUserOrder };
    }
    else {
      let allUserOrder = state.serverOrder.AllOfferDetails.map(x => { if (x.reservationId == orderInfo.reservationId) return { ...x, isError: true }; else return x });
      activeOrdersList = activeOrdersList.map(x => { if (x.reservationId == orderInfo.reservationId) return { ...x, isError: true }; else return x });
      console.log("Couldn't serve order for reservation" + orderInfo.reservationId);
      await storeData(APP_ALL_TRANSACTION, allUserOrder);
      return { AllOfferDetails: allUserOrder }
    }
  }
});

export const cancelOrder = createAsyncThunk("app/cancelOrder", async ({ networkID, provider, address, orderInfo, wallet }: IServeOrderAsyncThunk, { dispatch, getState }): Promise<any> => {
  //both Event & offer having same method name so need not create new message type
  const data = GetCreateCancelOrderMessage(orderInfo.reservationId);

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].OrderDispatcher,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    await dispatch(SendMetaTX(msg));
    // await storeData(APP_GET_CANCEL_ORDER_ID, orderInfo.reservationId);

    const state = getState() as RootState;
    const orderDispatch = Dispatcher__factory.connect(addresses[networkID].OrderDispatcher, provider);
    let orderDetails = await orderDispatch.reservations(orderInfo.reservationId);
    let isExpiry = Number(orderDetails.expiry) * 1000 < (new Date().getTime());
    if (orderDetails.cancelled || orderDetails.fulfilled || isExpiry) {
      let allUserOrder = state.serverOrder.AllOfferDetails.filter(x => x.reservationId != orderInfo.reservationId);
      activeOrdersList = activeOrdersList.filter(x => x.reservationId != orderInfo.reservationId);
      console.log("Order successfully cancelled for reservation" + orderInfo.reservationId);
      await storeData(APP_ALL_TRANSACTION, allUserOrder);
      return { AllOfferDetails: allUserOrder };
    }
    else {
      let allUserOrder = state.serverOrder.AllOfferDetails.map(x => { if (x.reservationId == orderInfo.reservationId) return { ...x, isError: true }; else return x });
      activeOrdersList = activeOrdersList.map(x => { if (x.reservationId == orderInfo.reservationId) return { ...x, isError: true }; else return x });
      console.log("Couldn't cancel order for reservation" + orderInfo.reservationId);
      await storeData(APP_ALL_TRANSACTION, allUserOrder);
      return { AllOfferDetails: allUserOrder }
    }
  }
});

export const onBanUser = createAsyncThunk("app/onBanUser", async ({ networkID, provider, address, orderInfo, wallet, banTime }: IBANOrderAsyncThunk, { dispatch }): Promise<any> => {
  //both Event & offer having same method name so need not create new message type
  const blockNumber = await provider.getBlockNumber();
  let timestamp = await (await provider.getBlock(blockNumber)).timestamp;
  console.log(timestamp);
  timestamp = timestamp + (banTime * 60 * 60);
  console.log(timestamp);
  const data = GetBanUserMessage(orderInfo.userId, timestamp);
  console.log(banTime)
  console.log("cancel order:", orderInfo, data);
  if (address) {
    let msg: IMessageMetaData = {
      to: orderInfo.entityId,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    console.log(msg)
    await dispatch(SendMetaTX(msg));
    // await dispatch(getOrderFromEvent({ networkID, provider, address, wallet }));
  }
});


export const getBartenderByEntity = createAsyncThunk("app/getBartenderEntity", async ({ networkID, provider, bartenderAddress }: { networkID, provider, bartenderAddress }): Promise<IEntityData> => {
  const entityObj = Entity__factory.connect(addresses[networkID].Entity_ADDRESS, provider);
  const entity = entityObj.getBartenderDetails(bartenderAddress, null);
  let entityData: IEntityData;
  return entityData;
});

export const getCollectionDetails = async ({ networkID, provider, collectionAddress, address, wallet }: ICollectionAsyncThunk, { entityData }: { entityData }, isCache = true, isBalanceRefresh = false): Promise<any> => {
  let collectionDetail = isCache ? await getData(APP_STORAGE_GET_COLLECTIONDETAILS(collectionAddress)) : null;
  if (collectionDetail && !isBalanceRefresh) return collectionDetail;

  let collection: ICollectionDetail = null;
  const collectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
  const tokenPriceCalcultor = TokenPriceCalculator__factory.connect(addresses[networkID].TokenPriceCalculator, provider);
  
  try {
    let collectionData = await collectionManager.getCollectionInfo(collectionAddress);
    let collectionName = collectionData?._name;
    let collectionSymbol = collectionData?._symbol;

    collection = {
      dataURI: "",
      image: "",
      details: "",
      name: "",
      address: "",
      symbol: "",
      isActive: false,
      area: undefined,
      entityAddress: "",
      price: 0,
      start: 0,
      end: 0,
      checkInNeeded: false,
      offerType: OfferType.NOTANOFFER,
      collectionType: CollectionType.ANY,
      collectionCount: 0,
      collectionIds: [],
      backgroundImage: "",
      linkCollection: [],
      subTitle: "",
      tokensEarned: 0,
      customOfferType: UICustomOfferType.EMPTY,
      whitelistedCollections: [],
      isAvailable: false
    };
    collection.dataURI = collectionData?._dataURI;

    let response = await getIPFSData(collection.dataURI);
    let ipfsData = await response.json();
    collection.image = getIPFSLink(ipfsData?.image);
    collection.details = ipfsData?.description;
    collection.subTitle = ipfsData?.subTitle;
    collection.name = collectionName;
    collection.address = collectionAddress;
    collection.symbol = collectionSymbol;
    collection.isActive = collectionData?._isActive;
    //collection.area = convertAreaData(collectionData._areaPoints, collectionData._areaRadius);
    collection.entityAddress = collectionData?._data?.entity;
    let price = Number(collectionData?._data?.price);
    collection.price = price / 1e6;
    collection.start = Number(collectionData?._data?.start) * 1000; //blocktimestamp to timestamp
    collection.end = Number(collectionData?._data?.end) * 1000;//blocktimestamp to timestamp
    collection.checkInNeeded = collectionData?._data?.checkInNeeded;
    collection.offerType = collectionData?._data?.offerType;
    collection.linkCollection = collectionData._linkedCollections;
    collection.collectionType = collectionData._collectionType;
    //collection.collectionCount = Number(await collectionContract.balanceOf(address));
    //collectioneids only get ids for collection within our system...
    //let tokenList = await collectionManager.getAllTokensForPatron(collectionAddress, address);
    //collection.collectionIds = collection?.collectionCount > 0 ? tokenList.map(x => { return Number(x) }) : []
    collection.backgroundImage = entityData[collection?.entityAddress]?.backgroundImage;
    //collection.isAvailable = isLocationAvailable(null, collection.area);
    collection.tokensEarned = price > 0 ? Number(await tokenPriceCalcultor.getTokensEligible(price)) / (1e18) : 0;
    if (collection.collectionType == CollectionType.EVENT)
    collection.customOfferType = UICustomOfferType.EVENT;
    if (collection.collectionType == CollectionType.OFFER) {
      if (collection.offerType == OfferType.FEATURED)
      collection.customOfferType = UICustomOfferType.FEATURED;
      else if (collection.offerType == OfferType.REGULAR)
      collection.customOfferType = UICustomOfferType.REGULAR;
    }

    await storeData(APP_STORAGE_GET_COLLECTIONDETAILS(collectionAddress), collection);
    return collection;
  }
  catch (ex) {
    console.log("getCollectionDetails-" + collectionAddress , ex.name, ex.message, ex.stack) 
  }
  finally {
    return collection;
  }
}

export const getOrderFromEvent = createAsyncThunk("app/getOrder", async ({ networkID, provider, address, wallet, fromBlock, toBlock }: { networkID, provider, address, wallet, fromBlock, toBlock }, { dispatch, getState }): Promise<any> => {
  console.log("getOrderFromEvent");
  const dispatcherHelper = DispatcherHelper__factory.connect(addresses[networkID].DispatcherHelper, provider);
  
  const userContract = User__factory.connect(addresses[networkID].User, provider);

  const state = getState() as RootState;
  const bartenderLst = state.entity.Bartender;
  const entityData = state.entity.EntityData;  
  let allEntityAddress = bartenderLst.map(x => x.entityAddress);
  let allUserOrder = [];
  let logevent = [];
  activeOrdersList = [];
  //need to verify
  let activeOrders = await dispatcherHelper.getActiveReservationsForEntity(allEntityAddress[0]);
  
  await Promise.all(activeOrders.map(async orderDetails => {
    let reservationIdNo = Number(orderDetails.id);
    //let orderDetails = await orderDispatch.reservations(reservationIdNo);
    let isExpiry = Number(orderDetails.expiry) * 1000 < (new Date().getTime());

    if (!orderDetails.fulfilled && !orderDetails.cancelled && !isExpiry) {
      const collectionAddress = orderDetails.offer;
      let offer = await getCollectionDetails({ networkID, provider, collectionAddress, address, wallet }, { entityData });
      //let user = await getData(APP_STORAGE_GET_USERDETAILS(orderDetails.patron));
     // if (user == null) {
      let userData = await userContract.userAttributes(orderDetails.patron);
      let baseimg = '';
      if(userData.avatarURI && userData.avatarURI != "" && userData.avatarURI != "no-avatar" && userData.avatarURI.includes('ipfs://'))
      {
        baseimg = await CheckAndGetIPFSLink(userData.avatarURI);
      }
      else{
        baseimg = require('../assets/images/loot8-default-avatar.png');
      }
      let user = {
        wallet: userData.wallet,
        name: userData.name,
        avatarURI: userData.avatarURI,
        id: Number(userData.id),
        baseimg: baseimg
      }
      //console.log("reservationId:,", Number(orderDetails.id));
     // console.log("user.avatarURI:,", user.avatarURI);
      // await storeData(APP_STORAGE_GET_USERDETAILS(orderDetails.patron),
      //   {
      //     wallet: user.wallet,
      //     name: user.name,
      //     avatarURI: user.avatarURI,
      //     id: Number(user.id),
      //     baseimg: baseimg
      //   });
       // user.id= Number(user.id)
      //}

      let allProcessedOrder = await getData(APP_ALL_TRANSACTION);
      let processedOrder = null;
      if(allProcessedOrder && allProcessedOrder.length > 0)
      {
        processedOrder = allProcessedOrder.filter(order => order.reservationId === reservationIdNo);
      }
      let data: UserOrderDetail = {
        reservationId: Number(orderDetails.id),
        transactionId: "",
        drinkName: offer.name,
        drinkImage: offer.image,
        userId: user.wallet,
        price: offer.price,
        token: Number(offer.tokensEarned.toFixed(2)),
        userName: user.name,
        userImage: user.baseimg,
        entityId: allEntityAddress[0],
        date: new Date(Number(orderDetails.created) * 1000),
        isPaid: !orderDetails.cashPayment,//need to take from contract
        isServed: false,
        offerId: 0,
        offerAddress: collectionAddress,
        blockDetails: "",
        isProcessing: processedOrder && processedOrder.length > 0 && processedOrder[0].isError ? false : processedOrder && processedOrder.length > 0 && processedOrder[0].isProcessing ? processedOrder[0].isProcessing : false,
        offerType: offer.offerType,
        userID: String(user.id),
        offerIsActive: offer.isActive,
        isError: false
      }
      allUserOrder.push(data);
      activeOrdersList.push(data); //keep a separate list of all active orders to be able to search within them
    }

  }));
  
  allUserOrder = allUserOrder.filter(x => x.offerIsActive);
  let cancelOrderId = await getData(APP_GET_CANCEL_ORDER_ID);
  //let allUserOrder = state.serverOrder.AllOfferDetails.map(x => { if (x.reservationId == cancelOrderId) return { ...x, isProcessing: false, isError: true }; else return x });
  let allUserOrderList = allUserOrder.filter(x => x.reservationId != cancelOrderId);
  activeOrdersList = activeOrdersList.filter(o => o.offerIsActive && o.reservationId != cancelOrderId);

  allUserOrderList.sort((a, b) => { return Number(b.date) - Number(a.date) });
  await storeData(APP_ALL_TRANSACTION, allUserOrder);
  //console.log("allUserOrder-allal", allUserOrder);
  return { AllOfferDetails: allUserOrderList };
});

export const processingOrder = createAsyncThunk("app/ProcessingOrder", async ({ networkID, provider, orderInfo }: { networkID, provider, orderInfo }, { dispatch, getState }): Promise<any> => {
  const state = getState() as RootState;
  let allUserOrder = state.serverOrder.AllOfferDetails.map(x => { if (x.reservationId == orderInfo.reservationId) return { ...x, isProcessing: true }; else return x });
  activeOrdersList = activeOrdersList.map(x => { if (x.reservationId == orderInfo.reservationId) return { ...x, isProcessing: true }; else return x });
  //console.log(allUserOrder)
  return { AllOfferDetails: allUserOrder };
});

export const searchOrders = createAsyncThunk('app/searchOrders', async ({ searchText }: { searchText }, { getState, dispatch }) => {
  const state = getState() as RootState;
  dispatch(setSearchText(searchText));
  let allOrders = [...state.serverOrder.AllOfferDetails];

  if (searchText && searchText.length > 0) {
    let searchTextLower = searchText.toLowerCase();

    allOrders = activeOrdersList.filter(o => o.userID.toLowerCase().indexOf(searchTextLower) > -1 ||
                                        o.userName.toLowerCase().indexOf(searchTextLower) > -1 || 
                                        o.drinkName.toLowerCase().indexOf(searchTextLower) > -1 || 
                                        o.price.toFixed(2).toLowerCase().indexOf(searchTextLower) > -1 || 
                                        o.token.toString().toLowerCase().indexOf(searchTextLower) > -1 || 
                                        o.reservationId.toString().toLowerCase().indexOf(searchTextLower) > -1) 
  }
  else {
    allOrders = activeOrdersList.filter(o => 1 === 1);
  }

  return {
    AllOfferDetails: allOrders
  }
});

const orderSlice = createSlice({
  name: "serveOrder",
  initialState,
  reducers: {
    serverOrderSuccess(state, action) {
      setAll(state, action.payload);
    },
    setSearchText(state, action){
      state.searchText = action.payload.searchText;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(serveOrder.pending, (state, action) => {
        state.processing = true;
      })
      .addCase(cancelOrder.pending, (state, action) => {
        state.processing = true;
      })
      .addCase(serveOrder.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.processing = false;
        // state.loading = false;
      })
      .addCase(getOrderFromEvent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrderFromEvent.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getOrderFromEvent.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.processing = false;
        // state.loading = false;
      })
      .addCase(onBanUser.fulfilled, (state, action) => {
        setAll(state, action.payload);
        // state.loading = false;
      })
      .addCase(processingOrder.fulfilled, (state, action) => {
        setAll(state, action.payload);
        // state.loading = false;
      })
      .addCase(searchOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchOrders.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(searchOrders.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })

  },
});

export const orderSliceReducer = orderSlice.reducer;
const baseInfo = (state: RootState) => state.serverOrder;

export const { serverOrderSuccess, setSearchText } = orderSlice.actions;

export const getOrderState = createSelector(baseInfo, OrderDispatcher => OrderDispatcher);